import React, { ReactElement } from "react";
import styles from "./style.module.scss";
export default function UterineCancer(): ReactElement {
    return (
        <section className={styles["wrapper"]}>
            <div className="md:py-24 py-10">
                <h2>Hair Relaxer Chemicals Linked to Uterine Cancer</h2>
                <span></span>
                <p>
                    Women who used chemical hair straightening or relaxer
                    products frequently (More than four times in the last year)
                    May have twice the risk of developing uterine cancer
                    compared to those who didn't use the products, according to
                    a new study by the national institutes of health (NIH).
                </p>
                <p>
                    While uterine cancer may only account for 3% of cancer
                    diagnoses each year, studies have shown that the rates for
                    this cancer are rising — particularly among black women —
                    with 65,950 new total cases estimated in 2022.
                </p>
            </div>
        </section>
    );
}
